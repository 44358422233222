import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  #root {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  
  :-moz-focusring {
    outline: none;
  }
`;
