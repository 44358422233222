import styled from 'styled-components';
import { Dropdown, Input, Segment } from 'semantic-ui-react';

export const FiltersContainer = styled(Segment)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FiltersContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FilterLabel = styled.p`
  width: 70px;
  min-width: 70px;
  margin: 0 10px 0 0;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.6;
`;

export const FilterDropDown = styled(Dropdown)`
  width: 100%;
  max-width: 300px;
  margin-right: 15px;
`;

export const SearchInput = styled(Input)`
  width: 400px;
  margin-right: 15px;
`;

export const EraseContainer = styled.div`
  padding-left: 15px;
  border-left: 1px solid ${props => props.theme.colors.light};

  button {
    border-radius: 50% !important;
  }
`;
