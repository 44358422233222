import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const LoginPage = safeLazy(() => import('./LoginPage'));
const ForgotPasswordPage = safeLazy(() => import('./ForgotPasswordPage'));
const ResetPasswordPage = safeLazy(() => import('./ResetPasswordPage'));
const ActivationPage = safeLazy(() => import('./ActivationPage'));
const ProfilePage = safeLazy(() => import('./ProfilePage'));

const AuthRoutes: FC = () => (
  <Routes>
    <Route path="/login" component={LoginPage} exact />
    <Route path="/password-reset/forgot" component={ForgotPasswordPage} exact />
    <Route path="/password-reset/:token" component={ResetPasswordPage} exact />
    <Route path="/activation/:token" component={ActivationPage} exact />
    <Route path="/profile" component={ProfilePage} exact />
  </Routes>
);

export default AuthRoutes;
