import React, { FC } from 'react';
import { Routes, Route } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const GiftsListPage = safeLazy(() => import('./ListPage'));
const CreateGiftPage = safeLazy(() => import('./CreatePage'));
const GiftDetailPage = safeLazy(() => import('./DetailPage'));

const GiftRoutes: FC = () => (
  <Routes>
    <Route path="/gifts" component={GiftsListPage} exact />
    <Route path="/gifts/new" component={CreateGiftPage} exact />
    <Route path="/gifts/:id" component={GiftDetailPage} exact />
  </Routes>
);

export default GiftRoutes;
