import React, { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Filter } from '../model';
import { Button, DropdownProps, InputOnChangeData } from 'semantic-ui-react';

import * as Styled from './Filters.styles';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

import isEqual from 'lodash.isequal';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce';

interface EraseProps {
  onClear: () => void;
}

const Erase: FC<EraseProps> = ({ onClear }) => (
  <Styled.EraseContainer>
    <Button icon="erase" onClick={onClear} />
  </Styled.EraseContainer>
);

interface SearchProps {
  value: string;
  onChange: (value: string) => void;
}

const Search: FC<SearchProps> = ({ value, onChange }) => {
  const handleChange = (_: ChangeEvent, { value }: InputOnChangeData) => onChange(value);

  return <Styled.SearchInput fluid icon="search" placeholder="Recherche" value={value} onChange={handleChange} />;
};

interface FilterElementProps {
  filter: Filter;
  value: string;
  onChange: (key: string, value: string) => void;
}

const FilterElement: FC<FilterElementProps> = ({ filter, value, onChange }) => {
  const handleChange = (_: SyntheticEvent, { value }: DropdownProps) => onChange(filter.key, value as string);

  return (
    <Styled.FilterDropDown
      fluid
      clearable
      selection
      search
      selectOnBlur={false}
      options={filter.options.map(opt => ({
        ...opt,
        value: opt.value?.toString(),
      }))}
      value={value}
      disabled={filter.disabled}
      placeholder={filter.label}
      noResultsMessage="Aucun résultat"
      onChange={handleChange}
    />
  );
};

interface FiltersProps {
  search?: boolean;
  filters?: ReadonlyArray<Filter>;
  onFilter?: (filters: { [key: string]: string }) => void;
}

const removeEmptyFilters = (filters: {
  [key: string]: string | string[] | null | undefined;
}): { [key: string]: string } => {
  return Object.keys(filters).reduce((acc, curr) => {
    const value = filters[curr];

    if (typeof value === 'string' && value !== '') {
      return {
        ...acc,
        [curr]: value,
      };
    }

    return acc;
  }, {});
};

export const Filters: FC<FiltersProps> = ({ filters, search, onFilter }) => {
  const location = useLocation();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState<string>('');
  const [filtersValue, setFiltersValue] = useState<{ [key: string]: string }>({});

  const fireFilters = useCallback(
    (filters: { [key: string]: string }) => {
      if (onFilter) {
        onFilter(filters);
      }
    },
    [onFilter],
  );

  const handleFilter = (newFiltersValue: { [key: string]: string }) => {
    if (!isEqual(filtersValue, newFiltersValue)) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(newFiltersValue),
      });
    }
  };

  const [debouncedHandleFilter] = useDebouncedCallback(
    (newFilters: { [key: string]: string }) => handleFilter(newFilters),
    400,
  );

  useEffect(() => {
    const values = removeEmptyFilters(queryString.parse(location.search));

    setFiltersValue(values);

    fireFilters(values);
  }, [location.search, fireFilters]);

  useEffect(() => {
    if (filtersValue.search) {
      setSearchValue(filtersValue.search);
    }
  }, [filtersValue.search]);

  const handleFilterChange = (key: string, value: string, debounce?: boolean) => {
    const newFilters = removeEmptyFilters({
      ...filtersValue,
      [key]: value,
    });

    if (debounce) {
      debouncedHandleFilter(newFilters);
    } else {
      handleFilter(newFilters);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    handleFilterChange('search', value, true);
  };

  const handleReset = () => {
    setSearchValue('');
    handleFilter({});
  };

  if ((filters && filters.length > 0) || search) {
    return (
      <Styled.FiltersContainer>
        <Styled.FiltersContent>
          {pipe(
            O.fromNullable(filters),
            O.map(f => (
              <>
                <Styled.FilterLabel>Filtrer par</Styled.FilterLabel>
                {f.map(filter => (
                  <FilterElement
                    key={filter.key}
                    filter={filter}
                    value={filtersValue[filter.key] || ''}
                    onChange={handleFilterChange}
                  />
                ))}
              </>
            )),
            O.toNullable,
          )}
        </Styled.FiltersContent>
        {!!search && <Search value={searchValue} onChange={handleSearchChange} />}
        <Erase onClear={handleReset} />
      </Styled.FiltersContainer>
    );
  }

  return null;
};
