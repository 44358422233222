import { createGlobalStyle } from 'styled-components';
import { primaryColor, secondaryColor } from '../../../styles/utils';

export const ToastsStyles = createGlobalStyle`
  .gest-team-toast {
    font-weight: 500;
  
    &.Toastify__toast--info {
      background: ${primaryColor()};
    }

    &.Toastify__toast--success {
      background: ${secondaryColor()};
      color: ${primaryColor()};
    }
    
    .Toastify__toast-body {
      padding: 0 8px;
    }
    
    .Toastify__close-button {
      opacity: 1;
    }
  }
`;
