import { RangeList, RangeRequest } from './model';
import { HttpTask } from '../../../core/http';
import { useCallback, useState } from 'react';
import { showToastsFromHttpRequest } from '../../utils/http';
import * as EI from 'fp-ts/lib/Either';

export const useRangeList = <T extends Object>(task: (req?: RangeRequest) => HttpTask<RangeList<T>>) => {
  const [range, setRange] = useState<RangeList<T>>(RangeList.getDefault());

  const fetchRange = useCallback(
    (req?: RangeRequest) => {
      return showToastsFromHttpRequest(task(req))().then(res => {
        if (EI.isRight(res)) {
          setRange(old => old.merge(res.right));
        }

        return res;
      });
    },
    [task],
  );

  return {
    range,
    handleRequest: fetchRange,
  };
};
