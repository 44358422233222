import React, { FC } from 'react';

import * as Styled from './Nav.styles';
import { Profile } from '../../modules/auth/model';

import logo from '../../assets/logos/logo.svg';
import { Link, NavLink } from 'react-router-dom';

interface NavEntry {
  title: string;
  link: string;
}

interface NavCategory {
  title: string;
  entries: Array<NavEntry>;
}

const navigation: Array<NavCategory> = [
  {
    title: 'Produits',
    entries: [
      {
        title: 'Fiches produit',
        link: '/products',
      },
      {
        title: 'Articles',
        link: '/articles',
      },
      {
        title: 'Catégories',
        link: '/categories',
      },
    ],
  },
  {
    title: 'Site Web',
    entries: [
      {
        title: "Page d'accueil",
        link: '/home-page',
      },
      {
        title: 'Mises en avant',
        link: '/advertising',
      },
      {
        title: 'Cadeaux',
        link: '/gifts',
      },
      {
        title: 'Catalogues',
        link: '/catalogs',
      },
      {
        title: 'Messages',
        link: '/warnings',
      },
    ],
  },
  {
    title: 'Administration',
    entries: [
      {
        title: 'Demandes de comptes',
        link: '/pending-customers',
      },
      {
        title: 'Gestionnaire de ressources',
        link: '/bucket',
      },
      {
        title: 'Utilisateurs',
        link: '/users',
      },
    ],
  },
];

interface NavProps {
  profile: Profile;
  onLogout: () => void;
}

const Nav: FC<NavProps> = ({ profile, onLogout }) => {
  return (
    <Styled.NavContainer>
      <Styled.NavHeader>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </Styled.NavHeader>
      <Styled.NavContent>
        {navigation.map((category, i) => (
          <Styled.NavCategory key={i}>
            <p>{category.title}</p>
            {category.entries.map((entry, j) => (
              <NavLink key={`${i} ${j}`} to={entry.link} activeClassName="active">
                {entry.title}
              </NavLink>
            ))}
          </Styled.NavCategory>
        ))}
      </Styled.NavContent>

      <Styled.NavFooter>
        <Link to="/profile">{`${profile.firstName} ${profile.lastName}`}</Link>
        <Styled.LogoutButton onClick={onLogout} />
      </Styled.NavFooter>
    </Styled.NavContainer>
  );
};

export default Nav;
