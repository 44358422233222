import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { toast } from 'react-toastify';
import { HttpError, HttpStatusCode, HttpTask } from '../../core/http';

export type ErrorMessages = { [key in HttpStatusCode]?: string };

export interface ToastsFromHttpRequestOptions {
  successMessage?: string;
  errorMessages?: ErrorMessages;
  hideUnexpectedError?: boolean;
  filterStatus?: Array<HttpStatusCode>;
}

export const showToastsFromHttpRequest = <R = unknown, E = unknown>(
  task: HttpTask<R, E>,
  options?: ToastsFromHttpRequestOptions,
): HttpTask<R, E> => {
  const optOptions = O.fromNullable(options);

  return pipe(
    task,
    TE.map(res => {
      pipe(
        optOptions,
        O.mapNullable(opt => opt.successMessage),
        O.map(message => toast.success(message)),
      );

      return res;
    }),
    TE.mapLeft(err => {
      pipe(
        optOptions,
        O.mapNullable(opt => opt.errorMessages),
        O.mapNullable(messages => messages[err.status]),
        O.alt(() => {
          const showError =
            O.isNone(optOptions) ||
            (!optOptions.value.hideUnexpectedError &&
              (optOptions.value.filterStatus === undefined ||
                (optOptions.value.filterStatus && !optOptions.value.filterStatus.includes(err.status))));

          return showError ? O.some('Une erreur technique est survenue') : O.none;
        }),
        O.map(message =>
          err.status === HttpStatusCode.UNAUTHORIZED ? toast.info('Votre session a expirée') : toast.error(message),
        ),
      );

      return err;
    }),
  );
};

export function mapOptionToHttpTask<T>(opt: O.Option<T>): HttpTask<T> {
  return TE.fromOption(() => HttpError.notFound)(opt);
}
