import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const AdvertisingListPage = safeLazy(() => import('./ListPage'));
const CreateAdvertisingPage = safeLazy(() => import('./CreatePage'));
const AdvertisingDetailPage = safeLazy(() => import('./DetailPage'));

const AdvertisingRoutes: FC = () => (
  <Routes>
    <Route path="/advertising" component={AdvertisingListPage} exact />
    <Route path="/advertising/new" component={CreateAdvertisingPage} exact />
    <Route path="/advertising/:id" component={AdvertisingDetailPage} exact />
  </Routes>
);

export default AdvertisingRoutes;
