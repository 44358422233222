import { Action } from 'redux';
import { FolderDetail, Resource } from '../../model';

export enum ResourcesActionsType {
  UPDATE_FOLDER = 'RESOURCES/UPDATE_FOLDER',
  RESET_FOLDER = 'RESOURCES/RESET_FOLDER',
  ADD_RESOURCE = 'RESOURCES/ADD_RESOURCE',
}

export interface UpdateResourceAction extends Action<ResourcesActionsType.UPDATE_FOLDER> {
  folder: FolderDetail;
}

export function updateFolder(folder: FolderDetail): UpdateResourceAction {
  return {
    type: ResourcesActionsType.UPDATE_FOLDER,
    folder,
  };
}

export type ResetFolderAction = Action<ResourcesActionsType.RESET_FOLDER>;

export function resetFolder(): ResetFolderAction {
  return {
    type: ResourcesActionsType.RESET_FOLDER,
  };
}

export interface AddResourceToFolderAction extends Action<ResourcesActionsType.ADD_RESOURCE> {
  folderId: number;
  resource: Resource;
}

export function addResourceToFolder(folderId: number, resource: Resource): AddResourceToFolderAction {
  return {
    type: ResourcesActionsType.ADD_RESOURCE,
    folderId,
    resource,
  };
}

export type ResourcesAction = UpdateResourceAction | ResetFolderAction | AddResourceToFolderAction;
