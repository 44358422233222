import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const BucketPage = safeLazy(() => import('./BucketPage'));

const BucketRoutes: FC = () => (
  <Routes>
    <Route path="/bucket" component={BucketPage} />
  </Routes>
);

export default BucketRoutes;
