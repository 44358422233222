import { lazy, ComponentType } from 'react';

export function safeLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy(() =>
    factory().catch(err => {
      window.location.reload();
      return err;
    }),
  );
}
