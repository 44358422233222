import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const PendingCustomersListPage = safeLazy(() => import('./ListPage'));
const PendingCustomerDetailPage = safeLazy(() => import('./DetailPage'));

const PendingCustomersRoutes: FC = () => (
  <Routes>
    <Route path="/pending-customers" component={PendingCustomersListPage} exact />
    <Route path="/pending-customers/:id" component={PendingCustomerDetailPage} exact />
  </Routes>
);

export default PendingCustomersRoutes;
