import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const ArticlesListPage = safeLazy(() => import('./ListPage'));

const ArticlesRoutes: FC = () => (
  <Routes>
    <Route path="/articles" component={ArticlesListPage} exact />
  </Routes>
);

export default ArticlesRoutes;
