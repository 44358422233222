import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

import { Profile } from './model';
import LineLoader from '../../layout/loaders/line-loader/LineLoader';
import { constVoid } from 'fp-ts/lib/function';

import * as AuthService from './service';

import * as O from 'fp-ts/lib/Option';
import * as EI from 'fp-ts/lib/Either';
import { useHistory } from 'react-router-dom';

export interface AuthContextValue {
  profile: O.Option<Profile>;
  updateProfile: (profile: Profile) => void;
  requestUpdateProfile: () => void;
  handleLogout: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  profile: O.none,
  updateProfile: constVoid,
  requestUpdateProfile: constVoid,
  handleLogout: constVoid,
});

export const AuthContextProvider: FC = ({ children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<O.Option<Profile>>(O.none);

  const fetchProfile = useCallback(() => {
    AuthService.getProfile()().then(res => {
      setProfile(O.fromEither(res));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const updateProfile = useCallback((profile: Profile) => setProfile(O.some(profile)), []);

  const handleLogout = useCallback(() => {
    AuthService.logout()().then(res => {
      if (EI.isLeft(res)) {
        console.error(res.left.log());
      }

      setProfile(O.none);

      history.push('/login');
    });
  }, [history]);

  const ctx: AuthContextValue = {
    profile,
    updateProfile,
    requestUpdateProfile: fetchProfile,
    handleLogout,
  };

  return <AuthContext.Provider value={ctx}>{loading ? <LineLoader /> : children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);
