import React from 'react';
import { TableRowProps } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { VirtualizedTableRowLinkBuilder } from './model';
import { filterRowData } from './utils';

import * as O from 'fp-ts/lib/Option';

interface A11yProps {
  'aria-rowindex': number;
  'aria-label'?: string;
  tabIndex?: number;
  onClick?: (event: any) => void;
  onDoubleClick?: (event: any) => void;
  onMouseOut?: (event: any) => void;
  onMouseOver?: (event: any) => void;
  onContextMenu?: (event: any) => void;
}

export default function rowRenderer<T extends Object>(
  {
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps,
  {
    rowLinkBuilder,
    openRowLinkInNewTab,
  }: { rowLinkBuilder?: VirtualizedTableRowLinkBuilder<T>; openRowLinkInNewTab?: boolean },
) {
  const a11yProps: A11yProps = { 'aria-rowindex': index + 1 };

  if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
    a11yProps['aria-label'] = 'row';
    a11yProps.tabIndex = 0;

    if (onRowClick) {
      a11yProps.onClick = event => onRowClick({ event, index, rowData });
    }
    if (onRowDoubleClick) {
      a11yProps.onDoubleClick = event => onRowDoubleClick({ event, index, rowData });
    }
    if (onRowMouseOut) {
      a11yProps.onMouseOut = event => onRowMouseOut({ event, index, rowData });
    }
    if (onRowMouseOver) {
      a11yProps.onMouseOver = event => onRowMouseOver({ event, index, rowData });
    }
    if (onRowRightClick) {
      a11yProps.onContextMenu = event => onRowRightClick({ event, index, rowData });
    }
  }

  const optRowData = filterRowData<T>(rowData);

  if (rowLinkBuilder != null && O.isSome(optRowData)) {
    const targetAttr = openRowLinkInNewTab ? '_blank' : '_self';

    return (
      <Link
        to={rowLinkBuilder(optRowData.value)}
        {...a11yProps}
        className={className}
        key={key}
        role="row"
        style={style}
        target={targetAttr}>
        {columns}
      </Link>
    );
  } else {
    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    );
  }
}
