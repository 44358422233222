import styled from 'styled-components';
import { primaryColor, secondaryColor } from '../../styles/utils';

import footerBackground from '../../assets/images/nav/footer-background.svg';
import logoutIcon from '../../assets/images/nav/logout.svg';

export const MENU_WIDTH = 270;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${MENU_WIDTH}px;
  height: 100%;
  background: ${primaryColor()};
`;

export const NavHeader = styled.div`
  padding: 11px 0 0 15px;
  min-height: 70px;
  background: ${props => props.theme.colors.white};
`;

export const NavContent = styled.div`
  flex: 1 1 auto;
  padding: 15px 0;
  overflow-y: auto;
`;

export const NavCategory = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 10px;

  > p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
  }

  > a {
    color: ${props => props.theme.colors.white};
    padding: 0 0 10px 10px;
    transition: color 0.15s ease-in-out;

    &:hover,
    &.active {
      color: ${secondaryColor()};
    }
  }
`;

export const NavFooter = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0 15px;
  background: url(${footerBackground}) no-repeat top left;

  > a {
    flex: 1 1 auto;
    padding-right: 5px;
    color: ${props => props.theme.colors.white};
    font-size: 15px;
    transition: color 0.15s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

export const LogoutButton = styled.button`
  width: 25px;
  height: 25px;
  background: url(${logoutIcon}) no-repeat center center;
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
`;
