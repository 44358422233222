import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const UsersListPage = safeLazy(() => import('./ListPage'));
const CreateUserPage = safeLazy(() => import('./CreatePage'));
const UserDetailPage = safeLazy(() => import('./DetailPage'));

const UsersRoutes: FC = () => (
  <Routes>
    <Route path="/users" component={UsersListPage} exact />
    <Route path="/users/new" component={CreateUserPage} exact />
    <Route path="/users/:id" component={UserDetailPage} exact />
  </Routes>
);

export default UsersRoutes;
