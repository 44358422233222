import { defaultUploadReduxState, UploadReduxState, UploadState } from './model';
import {
  UploadFileFailureAction,
  UploadFileAction,
  UploadingFileAction,
  UploadFileSuccessAction,
  UploadAction,
  UploadActionsType,
} from './actions';

export function handleUploadFile(
  state: UploadReduxState,
  { token, file, folderId }: UploadFileAction,
): UploadReduxState {
  return {
    uploads: {
      ...state.uploads,
      [token]: {
        token,
        file,
        state: UploadState.PENDING,
        folderId,
      },
    },
  };
}

export function handleUploadingFile(state: UploadReduxState, { token }: UploadingFileAction): UploadReduxState {
  return {
    uploads: {
      ...state.uploads,
      [token]: {
        ...state.uploads[token],
        state: UploadState.UPLOADING,
      },
    },
  };
}

export function handleUploadSuccess(state: UploadReduxState, { token }: UploadFileSuccessAction): UploadReduxState {
  const { [token]: removed, ...uploads } = state.uploads;

  return {
    uploads,
  };
}

export function handleUploadFail(state: UploadReduxState, { token, error }: UploadFileFailureAction): UploadReduxState {
  return {
    uploads: {
      ...state.uploads,
      [token]: {
        ...state.uploads[token],
        state: UploadState.ERROR,
        error,
      },
    },
  };
}

export default function(state = defaultUploadReduxState, action: UploadAction): UploadReduxState {
  switch (action.type) {
    case UploadActionsType.UPLOAD_FILE:
      return handleUploadFile(state, action);
    case UploadActionsType.UPLOADING_FILE:
      return handleUploadingFile(state, action);
    case UploadActionsType.UPLOAD_FILE_SUCCESS:
      return handleUploadSuccess(state, action);
    case UploadActionsType.UPLOAD_FILE_FAILURE:
      return handleUploadFail(state, action);
    default:
      return state;
  }
}
