import { v4 as uuid } from 'uuid';
import { HttpError } from '../../../../core/http';
import { Action } from 'redux';

export enum UploadActionsType {
  UPLOAD_FILE = 'UPLOAD/UPLOAD_FILE',
  UPLOADING_FILE = 'UPLOAD/UPLOADING_FILE',
  UPLOAD_FILE_SUCCESS = 'UPLOAD/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE = 'UPLOAD/UPLOAD_FILE_FAILURE',
}

export interface UploadFileAction extends Action<UploadActionsType.UPLOAD_FILE> {
  token: string;
  file: File;
  folderId: number;
}

export function uploadFile(file: File, folderId: number): UploadFileAction {
  return {
    type: UploadActionsType.UPLOAD_FILE,
    token: uuid(),
    folderId,
    file,
  };
}

export interface UploadingFileAction extends Action<UploadActionsType.UPLOADING_FILE> {
  token: string;
}

export function uploadingFile(token: string): UploadingFileAction {
  return {
    type: UploadActionsType.UPLOADING_FILE,
    token,
  };
}

export interface UploadFileSuccessAction extends Action<UploadActionsType.UPLOAD_FILE_SUCCESS> {
  token: string;
}

export function uploadFileSuccess(token: string): UploadFileSuccessAction {
  return {
    type: UploadActionsType.UPLOAD_FILE_SUCCESS,
    token,
  };
}

export interface UploadFileFailureAction extends Action<UploadActionsType.UPLOAD_FILE_FAILURE> {
  token: string;
  error: HttpError;
}

export function uploadFileFailure(token: string, error: HttpError): UploadFileFailureAction {
  return {
    type: UploadActionsType.UPLOAD_FILE_FAILURE,
    token,
    error,
  };
}

export type UploadAction = UploadFileAction | UploadingFileAction | UploadFileSuccessAction | UploadFileFailureAction;
