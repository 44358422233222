import React, { FC } from 'react';

import { Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Route, Routes } from './shared/modules/router/Routes';

import ProductRoutes from './modules/products/routes';
import ArticlesRoutes from './modules/articles/routes';
import CategoriesRoutes from './modules/categories/routes';

import HomePageRoutes from './modules/home-page/routes';
import AdvertisingRoutes from './modules/advertising/routes';
import GiftRoutes from './modules/gifts/routes';
import CatalogsRoutes from './modules/catalogs/routes';

import PendingCustomersRoutes from './modules/pending-customers/routes';
import BucketRoutes from './modules/bucket/routes';
import UsersRoutes from './modules/users/routes';

import AuthRoutes from './modules/auth/routes';
import WarningsRoutes from './modules/warnings/routes';

export const history = createBrowserHistory();

const RootRoutes: FC = () => (
  <Routes>
    <Redirect path="/" to="/products" exact />

    <Route path="/products" component={ProductRoutes} restricted />
    <Route path="/articles" component={ArticlesRoutes} restricted />
    <Route path="/categories" component={CategoriesRoutes} restricted />

    <Route path="/home-page" component={HomePageRoutes} restricted />
    <Route path="/advertising" component={AdvertisingRoutes} restricted />
    <Route path="/gifts" component={GiftRoutes} restricted />
    <Route path="/catalogs" component={CatalogsRoutes} restricted />
    <Route path="/warnings" component={WarningsRoutes} restricted />

    <Route path="/pending-customers" component={PendingCustomersRoutes} restricted />
    <Route path="/bucket" component={BucketRoutes} restricted />
    <Route path="/users" component={UsersRoutes} restricted />

    <Route path="/" component={AuthRoutes} />
  </Routes>
);

export default RootRoutes;
