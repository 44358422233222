import styled from 'styled-components';

import { Segment } from 'semantic-ui-react';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NoRowMessage = styled(Segment)`
  display: flex;
  align-items: center;
  height: 55px;
  border-radius: 0 0 5px 5px !important;
  box-shadow: none !important;
`;
