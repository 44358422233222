import { defaultResourcesState, ResourcesState } from './model';
import { AddResourceToFolderAction, ResourcesAction, ResourcesActionsType, UpdateResourceAction } from './actions';

import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

function handleUpdateFolder(state: ResourcesState, { folder }: UpdateResourceAction): ResourcesState {
  return {
    folder,
  };
}

function handleResetFolder(): ResourcesState {
  return {
    folder: null,
  };
}

function handleAddResourceToFolder(
  state: ResourcesState,
  { folderId, resource }: AddResourceToFolderAction,
): ResourcesState {
  return {
    folder: pipe(
      O.fromNullable(state.folder),
      O.map(f => {
        if (f.id === folderId) {
          return {
            ...f,
            resources: [...f.resources, resource],
          };
        }

        return f;
      }),
      O.toNullable,
    ),
  };
}

export default function(state = defaultResourcesState, action: ResourcesAction): ResourcesState {
  switch (action.type) {
    case ResourcesActionsType.UPDATE_FOLDER:
      return handleUpdateFolder(state, action);
    case ResourcesActionsType.RESET_FOLDER:
      return handleResetFolder();
    case ResourcesActionsType.ADD_RESOURCE:
      return handleAddResourceToFolder(state, action);
    default:
      return state;
  }
}
