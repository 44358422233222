import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const CatalogsListPage = safeLazy(() => import('./ListPage'));

const CatalogsRoutes: FC = () => (
  <Routes>
    <Route path="/catalogs" component={CatalogsListPage} exact />
  </Routes>
);

export default CatalogsRoutes;
