import React, { FC, Suspense } from 'react';
import { Route as ReactRoute, RouteProps as ReactRouteProps, Switch, Redirect } from 'react-router-dom';

import NotFound from '../../components/not-found/NotFound';
import LineLoader from '../../../layout/loaders/line-loader/LineLoader';

import { useAuthContext } from '../../../modules/auth/context';

import * as O from 'fp-ts/lib/Option';

export interface RouteProps {
  restricted?: boolean;
}

export const Route: FC<RouteProps & ReactRouteProps> = ({ restricted, ...routeProps }) => {
  const { profile } = useAuthContext();

  if (restricted && O.isNone(profile)) {
    return <Redirect to={{ pathname: '/login', state: { referrer: window.location.pathname } }} />;
  }

  return <ReactRoute {...routeProps} />;
};

export const Routes: FC = ({ children }) => (
  <Suspense fallback={<LineLoader />}>
    <Switch>
      {children}
      <ReactRoute component={NotFound} />
    </Switch>
  </Suspense>
);
