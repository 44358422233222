import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const WarningsListPage = safeLazy(() => import('./ListPage'));
const CreateWarningPage = safeLazy(() => import('./CreatePage'));
const WarningDetailPage = safeLazy(() => import('./DetailPage'));

const WarningsRoutes: FC = () => (
  <Routes>
    <Route path="/warnings" component={WarningsListPage} exact />
    <Route path="/warnings/new" component={CreateWarningPage} exact />
    <Route path="/warnings/:id" component={WarningDetailPage} exact />
  </Routes>
);

export default WarningsRoutes;
