import { Color, DefaultTheme } from 'styled-components';

const primary: Color = {
  100: '#e9eef7',
  200: '#d3dff0',
  300: '#98aed0',
  400: '#6683af',
  500: '#476798',
  600: '#476798',
  700: '#193764',
  800: '#0a2650',
  900: '#031d44',
};

const secondary: Color = {
  100: '#fffee8',
  200: '#fdfbd1',
  300: '#fbf9b9',
  400: '#f9f6a2',
  500: '#f7f38a',
  600: '#f4f073',
  700: '#f2ee5b',
  800: '#f0eb44',
  900: '#eee82c',
};

const tertiary: Color = {
  100: '#ffffff',
  200: '#f2f2f2',
  300: '#e4e5e8',
  400: '#cacbce',
  500: '#ababb1',
  600: '#6b6d77',
  700: '#474a58',
  800: '#242636',
  900: '#1b1b24',
};

const theme: DefaultTheme = {
  colors: {
    primary,
    secondary,
    tertiary,
    white: '#ffffff',
    light: '#f2f2f2',
  },
};

export default theme;
