import { ReactNode } from 'react';

import { DropdownItemProps } from 'semantic-ui-react';

import isEqual from 'lodash.isequal';

import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

export interface RangeRequest {
  startIndex?: number;
  endIndex?: number;
  search?: string;
  [key: string]: any;
}

export interface RangeResult<T extends Object, F extends Object = {}> {
  items: Array<T>;
  total: number;
  filter: F;
}

export class RangeList<T extends Object, F extends Object = {}> {
  constructor(
    public readonly items: ReadonlyMap<number, T> = new Map<number, T>(),
    public readonly total: number = 0,
    public readonly loading: boolean = false,
    public readonly filters?: F,
  ) {}

  merge(newList: RangeList<T, F>): RangeList<T, F> {
    if (isEqual(this.filters, newList.filters)) {
      return new RangeList(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newList.items.entries())]),
        newList.total,
        false,
        newList.filters,
      );
    } else {
      return newList;
    }
  }

  get(index: number): T | {} {
    return pipe(
      O.fromNullable(this.items.get(index)),
      O.getOrElse(() => ({})),
    );
  }

  getUndefined(index: number) {
    return this.items.get(index);
  }

  map<B>(fa: (a: T) => B): RangeList<B, F> {
    return new RangeList(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.loading,
      this.filters,
    );
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  static fromRangeResult<T extends Object, F extends Object = {}>(
    result: RangeResult<T, F>,
    request?: RangeRequest,
  ): RangeList<T, F> {
    const startIndex = pipe(
      O.fromNullable(request),
      O.mapNullable(r => r.startIndex),
      O.getOrElse(() => 0),
    );

    return new RangeList(
      new Map<number, T>(result.items.map((item, i) => [i + startIndex, item])),
      result.total,
      false,
      result.filter,
    );
  }

  static fromList<T extends Object, F extends Object = {}>(list: ReadonlyArray<T>): RangeList<T, F> {
    return new RangeList(new Map<number, T>(list.map((item, i) => [i, item])), list.length);
  }

  static getDefault<T extends Object, F extends Object = {}>(): RangeList<T, F> {
    return new RangeList(new Map<number, T>(), 0, true);
  }
}

export type VirtualizedTableColumnRenderer<T extends Object> = (item: T) => ReactNode;

export interface VirtualizedTableColumn<T extends Object> {
  label: string;
  renderer?: VirtualizedTableColumnRenderer<T>;
  flexGrow?: number;
  sortable?: boolean;
}

export type VirtualizedTableColumns<T extends { [key: string]: any }> = { [key: string]: VirtualizedTableColumn<T> };

export type VirtualizedTableRowLinkBuilder<T extends Object> = (item: T) => string;

export type VirtualizedTableRowClickHandler<T extends Object> = (item: T, index: number) => string;

export interface BaseFilter {
  key: string;
  label?: string;
  disabled?: boolean;
}

export interface DropDownFilter extends BaseFilter {
  type: 'dropdown';
  options: Array<DropdownItemProps>;
}

export type Filter = DropDownFilter;

export interface VirtualizedTableProps<T extends Object> {
  items: RangeList<T>;
  columns: VirtualizedTableColumns<T>;
  onRequest: (request: RangeRequest) => Promise<unknown>;
  search?: boolean;
  filters?: Array<Filter>;
  rowLinkBuilder?: VirtualizedTableRowLinkBuilder<T>;
  openRowLinkInNewTab?: boolean;
  onRowClick?: VirtualizedTableRowClickHandler<T>;
  rowHeight?: number;
  onSort?: (value?: string) => void;
}
