import axios from 'axios';

import { camelToSnake } from '../core/utils/string.utils';

import { v4 as uuid } from 'uuid';
import { TRANSACTION_ID_HEADER_KEY } from '../core/http';

function convertParamsToSnakeCase(params: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(params)
    .map(([key, value]) => [camelToSnake(key), value])
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      };
    }, {});
}

function configureInterceptors() {
  axios.interceptors.request.use(config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        [TRANSACTION_ID_HEADER_KEY]: uuid(),
      },
      params: config.params ? convertParamsToSnakeCase(config.params) : undefined,
      withCredentials: true,
    };
  });
}

export default function configureAxios() {
  configureInterceptors();
}
