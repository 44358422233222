import React, { PureComponent } from 'react';

import {
  AutoSizer,
  Column,
  Index,
  IndexRange,
  InfiniteLoader,
  RowMouseEventHandlerParams,
  Table,
  TableCellRenderer,
  TableRowProps,
} from 'react-virtualized';

import { VirtualizedTableColumn, VirtualizedTableProps } from '../model';
import rowRenderer from '../rowRenderer';
import { filterRowData } from '../utils';

import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

import { Header, Placeholder } from 'semantic-ui-react';

import * as Styled from './VirtualizedTable.styles';
import { constVoid } from 'fp-ts/lib/function';
import { Filters } from './Filters';

const HEADER_HEIGHT = 50;
const ROW_HEIGHT = 50;

interface VirtualizedTableState {
  filters: { [key: string]: string };
}

export class VirtualizedTable<T extends Object> extends PureComponent<VirtualizedTableProps<T>, VirtualizedTableState> {
  state: VirtualizedTableState = {
    filters: {},
  };

  private getRow = ({ index }: Index): T | {} => this.props.items.get(index);

  private isRowLoaded = ({ index }: Index): boolean => this.props.items.has(index);

  private loadMoreRows = ({ startIndex, stopIndex }: IndexRange): Promise<unknown> =>
    this.props.onRequest({ startIndex, endIndex: stopIndex, ...this.state.filters });

  private handleFilter = (filters: { [key: string]: string }) => {
    this.setState({ filters }, () => this.props.onRequest(filters));
  };

  private renderRow = (props: TableRowProps) => rowRenderer(props, this.props);

  private renderCell = (column: VirtualizedTableColumn<T>): TableCellRenderer => ({ dataKey, rowData }) => {
    return pipe(
      filterRowData<T>(rowData),
      O.fold(
        () => (
          <Placeholder fluid>
            <Placeholder.Line length="full" />
          </Placeholder>
        ),
        data =>
          pipe(
            O.fromNullable(column.renderer),
            O.map(renderer => renderer(data)),
            O.alt(() =>
              pipe(
                O.fromNullable(rowData[dataKey]),
                O.filter(data => typeof data === 'string' || typeof data === 'number'),
              ),
            ),
            O.toNullable,
          ),
      ),
    );
  };

  private noRowsRenderer = (): JSX.Element | null => {
    const { items } = this.props;

    return items.loading ? null : (
      <Styled.NoRowMessage>
        <Header as="h5" color="grey">
          Aucune donnée à afficher.
        </Header>
      </Styled.NoRowMessage>
    );
  };

  private handleRowClick = ({ rowData, index }: RowMouseEventHandlerParams): void =>
    pipe(
      filterRowData<T>(rowData),
      O.map(item => {
        if (this.props.onRowClick) {
          this.props.onRowClick(item, index);
        }

        return true;
      }),
      O.fold(constVoid, constVoid),
    );

  render() {
    const { items, columns, filters, search, rowHeight, rowLinkBuilder, onRowClick } = this.props;

    const className = `table-wrapper ${rowLinkBuilder || onRowClick ? 'with-hover' : ''}`;

    const rowH = pipe(
      O.fromNullable(rowHeight),
      O.getOrElse(() => ROW_HEIGHT),
    );

    return (
      <Styled.VirtualizedTableContainer>
        <Filters filters={filters} search={search} onFilter={this.handleFilter} />
        <InfiniteLoader rowCount={items.total} isRowLoaded={this.isRowLoaded} loadMoreRows={this.loadMoreRows}>
          {({ onRowsRendered, registerChild }) => (
            <div className={className}>
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    ref={registerChild}
                    width={width}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    rowGetter={this.getRow}
                    rowRenderer={this.renderRow}
                    rowHeight={rowH}
                    headerHeight={HEADER_HEIGHT}
                    rowCount={items.total}
                    onRowClick={this.handleRowClick}
                    noRowsRenderer={this.noRowsRenderer}>
                    {Object.keys(columns).map(key => (
                      <Column
                        key={key}
                        dataKey={key}
                        label={columns[key].label}
                        width={115}
                        flexGrow={columns[key].flexGrow || 1}
                        disableSort
                        cellRenderer={this.renderCell(columns[key])}
                      />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            </div>
          )}
        </InfiniteLoader>
      </Styled.VirtualizedTableContainer>
    );
  }
}
