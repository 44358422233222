import { buffers, channel, Channel } from 'redux-saga';

import { call, fork, take, takeEvery, put } from 'redux-saga/effects';

import { UploadActionsType, UploadFileAction, uploadFileFailure, uploadFileSuccess, uploadingFile } from './actions';

import * as BucketService from '../../service';

import * as EI from 'fp-ts/lib/Either';
import { addResourceToFolder } from '../resources/actions';
import { HttpResult } from '../../../../core/http';
import { Resource } from '../../model';

const INIT_BUFFER_SIZE = 20;
const MAX_CONCURRENT_UPLOADS = 5;

const createUploadChannel = () => channel(buffers.expanding(INIT_BUFFER_SIZE));

function* watchUploads() {
  const chan = yield call(createUploadChannel);

  for (let i = 0; i < MAX_CONCURRENT_UPLOADS; i++) {
    yield fork(handleUpload, chan);
  }

  yield takeEvery(UploadActionsType.UPLOAD_FILE, addPendingUpload, chan);
}

function* addPendingUpload(chan: Channel<unknown>, upload: UploadFileAction) {
  yield put(chan, upload);
}

function* handleUpload(chan: Channel<unknown>) {
  while (true) {
    const { token, file, folderId }: UploadFileAction = yield take(chan);

    yield put(uploadingFile(token));

    const res: HttpResult<Resource> = yield call(BucketService.addResourceToFolder, folderId, file);

    if (EI.isRight(res)) {
      yield put(uploadFileSuccess(token));
      yield put(addResourceToFolder(folderId, res.right));
    } else {
      yield put(uploadFileFailure(token, res.left));
    }
  }
}

export default function* root() {
  yield fork(watchUploads);
}
