import { HttpError } from '../../../../core/http';

export enum UploadState {
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR',
}

export interface Upload {
  token: string;
  file: File;
  state: UploadState;
  folderId?: number;
  error?: HttpError;
}

export type UploadMap = { [key: string]: Upload };

export interface UploadReduxState {
  uploads: UploadMap;
}

export const defaultUploadReduxState: UploadReduxState = {
  uploads: {},
};
