import React, { FC } from 'react';

import { store } from './store';
import { Provider } from 'react-redux';

import { Router } from 'react-router-dom';
import RootRoutes, { history } from './routes';

import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import { GlobalStyles } from './styles/global.styles';

import Layout from './layout/Layout';
import { AuthContextProvider } from './modules/auth/context';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <GlobalStyles />
        <Router history={history}>
          <AuthContextProvider>
            <Layout>
              <RootRoutes />
            </Layout>
          </AuthContextProvider>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
