import styled from 'styled-components';
import { MENU_WIDTH } from './nav/Nav.styles';

export const LayoutContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const RouteContainer = styled.div<{ fullWidth: boolean }>`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : `calc(100% - ${MENU_WIDTH}px)`)};
  height: 100%;
`;
