import React, { FC } from 'react';
import { Route, Routes } from '../../shared/modules/router/Routes';
import { safeLazy } from '../../core/router';

const ProductListPage = safeLazy(() => import('./ListPage'));
const ProductReferenceDetail = safeLazy(() => import('./ReferencePage'));
const ProductDetailPage = safeLazy(() => import('./DetailPage'));

const ProductRoutes: FC = () => (
  <Routes>
    <Route path="/products" component={ProductListPage} exact />
    <Route path="/products/references/:id" component={ProductReferenceDetail} exact />
    <Route path="/products/:id" component={ProductDetailPage} />
  </Routes>
);

export default ProductRoutes;
