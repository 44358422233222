import {
  AuthenticateParams,
  ChangePasswordParams,
  ForgotPasswordParams,
  PasswordParams,
  Profile,
  UpdateProfileParams,
} from './model';
import { httpService, HttpTask } from '../../core/http';

import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/pipeable';

export function getProfile(): HttpTask<Profile> {
  return httpService.get('/back/profile');
}

export function updateProfile(params: UpdateProfileParams): HttpTask<void> {
  return httpService.post('/back/profile', params);
}

export function updateProfilePassword(params: ChangePasswordParams): HttpTask<void> {
  return httpService.post('/back/profile/password', params);
}

export function authenticate(params: AuthenticateParams): HttpTask<Profile> {
  return pipe(httpService.post('/back/authenticate', params), TE.chain(getProfile));
}

export function logout(): HttpTask<void> {
  return httpService.delete('/back/authenticate');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask<void> {
  return httpService.post('/back/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/back/password-reset/${token}`);
}

export function resetPassword(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/back/password-reset/${token}`, params);
}

export function getActivationDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/back/activation/${token}`);
}

export function activateAccount(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/back/activation/${token}`, params);
}
