import { HttpResult, httpService, HttpTask } from '../../core/http';
import { RangeList, RangeRequest } from '../../shared/modules/pagination';
import { FolderNameParams, FolderItem, FolderDetail, Resource } from './model';

const URI = '/back/bucket';

export function listBucketFolders(request?: RangeRequest): HttpTask<RangeList<FolderItem>> {
  return httpService.getRange(`${URI}/folders`, request);
}

export function createBucketFolder(params: FolderNameParams): HttpTask<{ id: number }> {
  return httpService.post(`${URI}/folders`, params);
}

export function getBucketFolder(id: number): HttpTask<FolderDetail> {
  return httpService.get(`${URI}/folders/${id}`);
}

export function updateBucketFolder(id: number, params: FolderNameParams): HttpTask<void> {
  return httpService.post(`${URI}/folders/${id}`, params);
}

export function deleteBucketFolder(id: number): HttpTask<void> {
  return httpService.delete(`${URI}/folders/${id}`);
}

function getFormDataFromFile(file: File): FormData {
  const formData = new FormData();

  formData.append('file', file);

  return formData;
}

export function addResourceToFolder(folderId: number, file: File): Promise<HttpResult<Resource>> {
  return httpService.post<Resource>(`${URI}/folders/${folderId}/resources`, getFormDataFromFile(file))();
}

export function addResourceToDefaultFolder(file: File): HttpTask<Resource> {
  return httpService.post<Resource>(`${URI}/resources`, getFormDataFromFile(file));
}

export function listResources(request?: RangeRequest): HttpTask<RangeList<Resource>> {
  return httpService.getRange(`${URI}/resources`, request);
}

export function editResource(id: number, name: string): HttpTask<void> {
  return httpService.post(`${URI}/resources/${id}`, { name });
}

export function deleteResource(id: number): HttpTask<void> {
  return httpService.delete(`${URI}/resources/${id}`);
}

export function processResource(id: number, imgixUrl: string): HttpTask<Resource> {
  return httpService.post(`${URI}/resources/${id}/process`, { url: imgixUrl });
}
